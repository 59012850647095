import { useState, useEffect } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";
import locdata from "./data/locdata.json";


import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import { Errorpage } from "./components/pages/errorpage";
import { Thanks } from "./components/pages/thanks";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";
import { Bihar } from "./components/location/Bihar";
import { Jharkhand } from "./components/location/Jharkhand";
import { Bengal } from "./components/location/Bengal";
import { Popularcitydata } from "./components/location/Popularcitydata";
import { Usefulnearme } from "./components/location/Usefulnearme";
import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";
import Popularcity from "./components/location/nearmelink/popularcity";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {


  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=919031062011" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel:9031062011">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
      <Navigation />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="*" element={<Errorpage />} />
        <Route exact path="/thanks" element={<Thanks />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/return-policy" element={<Returnypolicy />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/faq" element={<Faqp />} />

        <Route path="/ro-service" element={<Usefulnearme title={locdata.RoService.title} number={locdata.RoService.number} />} />
        <Route path="/buy-water-purifier" element={<Usefulnearme title={locdata.WaterPurifier.title} number={locdata.WaterPurifier.number} />} />
        <Route path="/water-purifier-installation" element={<Usefulnearme title={locdata.Installation.title} number={locdata.Installation.number} />} />
        <Route path="/amc-plans" element={<Usefulnearme title={locdata.AMCPlans.title} number={locdata.AMCPlans.number} />} />
        <Route path="/customer-care" element={<Usefulnearme title={locdata.CustomerCare.title} number={locdata.CustomerCare.number} />} />
        <Route path="/charges" element={<Usefulnearme title={locdata.Charges.title} number={locdata.Charges.number} />} />
        <Route path="/ro-service-kit" element={<Usefulnearme title={locdata.ServiceKit.title} number={locdata.ServiceKit.number} />} />
        <Route path="/ro-plant" element={<Usefulnearme title={locdata.Plant.title} number={locdata.Plant.number} />} />
        <Route path="/ro-softener" element={<Usefulnearme title={locdata.Softener.title} number={locdata.Softener.number} />} />
        <Route path="/water-purifier-spare" element={<Usefulnearme title={locdata.Spare.title} number={locdata.Spare.number} />} />
        <Route path="/dm-plant-manufacturers" element={<Usefulnearme title={locdata.Manufacturers.title} number={locdata.Manufacturers.number} />} />
        <Route path="/sewage-treatment-plant-manufacturers" element={<Usefulnearme title={locdata.SewageTreatment.title} number={locdata.SewageTreatment.number} />} />
        <Route path="/atm" element={<Usefulnearme title={locdata.ATM.title} number={locdata.ATM.number} />} />
        <Route path="/effluent-treatment-manufacturers" element={<Usefulnearme title={locdata.EffluentTreatment.title} number={locdata.EffluentTreatment.number} />} />


        <Route path="/ro-service-Araria" element={<Bihar title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/ro-service-Arwal" element={<Bihar title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/ro-service-Aurangabad" element={<Bihar title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/ro-service-Banka" element={<Bihar title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/ro-service-Begusarai" element={<Bihar title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/ro-service-Bhabua" element={<Bihar title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/ro-service-Bhagalpur" element={<Bihar title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/ro-service-Bhojpur" element={<Bihar title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/ro-service-Bihar-Sharif" element={<Bihar title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/ro-service-Buxar" element={<Bihar title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/ro-service-Chhapra" element={<Bihar title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/ro-service-Darbhanga" element={<Bihar title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/ro-service-East-Champaran" element={<Bihar title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/ro-service-Gaya" element={<Bihar title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/ro-service-Gopalganj" element={<Bihar title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/ro-service-Jamui" element={<Bihar title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/ro-service-Jehanabad" element={<Bihar title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/ro-service-Khagaria" element={<Bihar title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/ro-service-Kishanganj" element={<Bihar title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/ro-service-Kaimur" element={<Bihar title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/ro-service-Katihar" element={<Bihar title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/ro-service-Lakhisarai" element={<Bihar title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/ro-service-Madhubani" element={<Bihar title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/ro-service-Munger" element={<Bihar title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/ro-service-Madhepura" element={<Bihar title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/ro-service-Muzaffarpur" element={<Bihar title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/ro-service-Nalanda" element={<Bihar title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/ro-service-Nawada" element={<Bihar title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/ro-service-Patna" element={<Bihar title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/ro-service-Purnia" element={<Bihar title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/ro-service-Rohtas" element={<Bihar title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/ro-service-Saharsa" element={<Bihar title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/ro-service-Samastipur" element={<Bihar title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/ro-service-Sasaram" element={<Bihar title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/ro-service-Sheohar" element={<Bihar title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/ro-service-Sheikhpura" element={<Bihar title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/ro-service-Saran" element={<Bihar title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/ro-service-Sitamarhi" element={<Bihar title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/ro-service-Supaul" element={<Bihar title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/ro-service-Siwan" element={<Bihar title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/ro-service-Vaishali" element={<Bihar title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/ro-service-West-Champaran" element={<Bihar title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/ro-service-Garhwa" element={<Jharkhand title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/ro-service-Palamu" element={<Jharkhand title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/ro-service-Latehar" element={<Jharkhand title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/ro-service-Chatra" element={<Jharkhand title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/ro-service-Hazaribagh" element={<Jharkhand title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/ro-service-Jamshedpur" element={<Jharkhand title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/ro-service-Koderma" element={<Jharkhand title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/ro-service-Giridih" element={<Jharkhand title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/ro-service-Ramgarh" element={<Jharkhand title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/ro-service-Bokaro" element={<Jharkhand title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/ro-service-Dhanbad" element={<Jharkhand title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/ro-service-Lohardaga" element={<Jharkhand title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/ro-service-Gumla" element={<Jharkhand title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/ro-service-Simdega" element={<Jharkhand title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/ro-service-Ranchi" element={<Jharkhand title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/ro-service-Khunti" element={<Jharkhand title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/ro-service-Singhbhum" element={<Jharkhand title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/ro-service-Saraikela-Kharsawan" element={<Jharkhand title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/ro-service-Jamtara" element={<Jharkhand title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/ro-service-Deoghar" element={<Jharkhand title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/ro-service-Dumka" element={<Jharkhand title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/ro-service-Pakur" element={<Jharkhand title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/ro-service-Godda" element={<Jharkhand title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/ro-service-Sahebganj" element={<Jharkhand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/ro-service-Alipurduar" element={<Bengal title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/ro-service-Bankura" element={<Bengal title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/ro-service-Birbhum" element={<Bengal title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/ro-service-Cooch-Behar" element={<Bengal title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/ro-service-Dakshin-Dinajpur" element={<Bengal title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/ro-service-Darjeeling" element={<Bengal title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/ro-service-Durgapur" element={<Bengal title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/ro-service-Hooghly" element={<Bengal title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/ro-service-Howrah" element={<Bengal title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/ro-service-Jalpaiguri" element={<Bengal title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/ro-service-Jhargram" element={<Bengal title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/ro-service-Kalimpong" element={<Bengal title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/ro-service-Kolkata" element={<Bengal title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/ro-service-Malda" element={<Bengal title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/ro-service-Murshidabad" element={<Bengal title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/ro-service-Nadia" element={<Bengal title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/ro-service-North-24-Parganas" element={<Bengal title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/ro-service-Paschim-Bardhaman" element={<Bengal title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/ro-service-Paschim-Medinipur" element={<Bengal title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/ro-service-Purba-Bardhaman" element={<Bengal title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/ro-service-Purba-Medinipur" element={<Bengal title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/ro-service-South-24-Parganas" element={<Bengal title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/ro-service-Uttar-Dinajpur" element={<Bengal title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/ro-service-Mumbai" element={<Popularcitydata title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/ro-service-Delhi" element={<Popularcitydata title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/ro-service-Bengaluru" element={<Popularcitydata title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/ro-service-Hyderabad" element={<Popularcitydata title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/ro-service-Ahmedabad" element={<Popularcitydata title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/ro-service-Chennai" element={<Popularcitydata title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/ro-service-Surat" element={<Popularcitydata title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/ro-service-Pune" element={<Popularcitydata title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/ro-service-Jaipur" element={<Popularcitydata title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/ro-service-Lucknow" element={<Popularcitydata title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/ro-service-Kanpur" element={<Popularcitydata title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/ro-service-Nagpur" element={<Popularcitydata title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/ro-service-Indore" element={<Popularcitydata title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/ro-service-Thane" element={<Popularcitydata title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/ro-service-Bhopal" element={<Popularcitydata title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/ro-service-Visakhapatnam" element={<Popularcitydata title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/ro-service-Vadodara" element={<Popularcitydata title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/ro-service-Ghaziabad" element={<Popularcitydata title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/ro-service-Ludhiana" element={<Popularcitydata title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/ro-service-Agra" element={<Popularcitydata title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/ro-service-Nashik" element={<Popularcitydata title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/ro-service-Faridabad" element={<Popularcitydata title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/ro-service-Meerut" element={<Popularcitydata title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/ro-service-Rajkot" element={<Popularcitydata title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/ro-service-Varanasi" element={<Popularcitydata title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/ro-service-Srinagar" element={<Popularcitydata title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/ro-service-Amritsar" element={<Popularcitydata title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/ro-service-Navi-Mumbai" element={<Popularcitydata title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/ro-service-Prayagraj" element={<Popularcitydata title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/ro-service-Jabalpur" element={<Popularcitydata title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/ro-service-Gwalior" element={<Popularcitydata title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/ro-service-Coimbatore" element={<Popularcitydata title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/ro-service-Vijayawada" element={<Popularcitydata title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/ro-service-Jodhpur" element={<Popularcitydata title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/ro-service-Madurai" element={<Popularcitydata title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/ro-service-Raipur" element={<Popularcitydata title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/ro-service-Kota" element={<Popularcitydata title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/ro-service-Guwahati" element={<Popularcitydata title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/ro-service-Chandigarh" element={<Popularcitydata title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/ro-service-Thiruvananthapuram" element={<Popularcitydata title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/ro-service-Solapur" element={<Popularcitydata title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/ro-service-Tiruppur" element={<Popularcitydata title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/ro-service-Moradabad" element={<Popularcitydata title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/ro-service-Mysore" element={<Popularcitydata title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/ro-service-Gurgaon" element={<Popularcitydata title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/ro-service-Aligarh" element={<Popularcitydata title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/ro-service-Jalandhar" element={<Popularcitydata title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/ro-service-Bhubaneswar" element={<Popularcitydata title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/ro-service-Noida" element={<Popularcitydata title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/ro-service-Jamshedpur" element={<Popularcitydata title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/ro-service-Cuttack" element={<Popularcitydata title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/ro-service-Bhavnagar" element={<Popularcitydata title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/ro-service-Dehradun" element={<Popularcitydata title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/ro-service-Rourkela" element={<Popularcitydata title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/ro-service-Jamnagar" element={<Popularcitydata title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/ro-service-Ujjain" element={<Popularcitydata title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/ro-service-Jammu" element={<Popularcitydata title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/ro-service-Mangalore" element={<Popularcitydata title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/ro-service-Erode" element={<Popularcitydata title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/ro-service-Udaipur" element={<Popularcitydata title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/ro-service-Rajahmundry" element={<Popularcitydata title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/ro-service-Patiala" element={<Popularcitydata title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/ro-service-Agartala" element={<Popularcitydata title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/ro-service-Tirupati" element={<Popularcitydata title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/ro-service-Sambalpur" element={<Popularcitydata title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/ro-service-Bilaspur" element={<Popularcitydata title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/ro-service-Alwar" element={<Popularcitydata title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/ro-service-Bardhaman" element={<Popularcitydata title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/ro-service-Sonipat" element={<Popularcitydata title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/ro-service-New-Delhi" element={<Popularcitydata title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/ro-service-Puducherry" element={<Popularcitydata title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/ro-service-Haridwar" element={<Popularcitydata title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/ro-service-Vijayanagaram" element={<Popularcitydata title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/ro-service-Sri-Ganganagar" element={<Popularcitydata title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/ro-service-Secunderabad" element={<Popularcitydata title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/ro-service-Yamunanagar" element={<Popularcitydata title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/ro-service-Gandhinagar" element={<Popularcitydata title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/ro-service-Ambala" element={<Popularcitydata title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/ro-service-Vellore" element={<Popularcitydata title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/ro-service-Shimla" element={<Popularcitydata title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/ro-service-Amaravati" element={<Popularcitydata title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />

      </Routes>
      <Popularcity />
      <Footerdetail data={landingPageData.Contact} />
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;

